import { isEqual } from "lodash";
import { updateUser } from "../api/usersApi";
import { ANSWERS_STORAGE_NAME } from "../authentication/constants";
import { User } from "../authentication/userContext";

export const getAnsweredQuestions = (
  user: User | null
): Record<string, boolean> => {
  if (user) {
    const answeredQuestionsLocalStorage =
      localStorage.getItem(ANSWERS_STORAGE_NAME);
    const storageAnsweredQuestions = answeredQuestionsLocalStorage
      ? JSON.parse(answeredQuestionsLocalStorage)
      : {};

    const mergedAnsweredQuestions = {
      ...storageAnsweredQuestions,
      ...user.answeredQuestions,
    };

    if (!isEqual(mergedAnsweredQuestions, user.answeredQuestions)) {
      updateUser(user.id, { answeredQuestions: mergedAnsweredQuestions });
    }

    if (!isEqual(mergedAnsweredQuestions, storageAnsweredQuestions)) {
      localStorage.setItem(
        ANSWERS_STORAGE_NAME,
        JSON.stringify(mergedAnsweredQuestions)
      );
    }

    return mergedAnsweredQuestions;
  } else {
    const answeredQuestionsLocalStorage =
      localStorage.getItem(ANSWERS_STORAGE_NAME);
    return answeredQuestionsLocalStorage
      ? JSON.parse(answeredQuestionsLocalStorage)
      : {};
  }
};
