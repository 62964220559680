import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../userContext";
import { useMixpanel } from "react-mixpanel-browser";

const PremiumOptions: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const mixpanel = useMixpanel();

  const handlePaymentOptionClick = (months: number) => {
    mixpanel?.track("payment_options_select", {
      email: user?.email,
      name: user?.fullName,
      months,
    });

    if (!user) {
      navigate("/signup");
      return;
    }

    navigate(`/payment-paypal/${months}`);
  };

  mixpanel?.track("payment_options_view", {
    email: user?.email,
    name: user?.fullName,
  });

  return (
    <div>
      <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200 py-16 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-extrabold text-dnBlue mb-8">
            Choose Your Subscription
          </h1>

          {user?.isPremium && (
            <div className="mb-4 p-4 bg-green-100 border border-green-300 rounded-lg shadow-md text-green-800">
              <h2 className="font-semibold text-lg">
                You're already a premium member!
              </h2>
              <p className="text-sm">
                You can use the options below to extend your membership.
              </p>
            </div>
          )}

          <div className="text-lg text-gray-700 mb-8 leading-relaxed">
            <span className="font-semibold">
              Choose the perfect one-time payment plan
            </span>{" "}
            — no auto-renewals, no hidden fees!
            <div className="mt-1">
              Get full access to our SQL interview question bank and start
              mastering your skills today.
            </div>
          </div>

          <div className="flex justify-center items-center min-h-[200px]">
            <div className="text-start text-dnBlue mb-12 text-md bg-white shadow-lg rounded-lg p-6 w-full max-w-lg">
              <ul className="list-disc list-inside space-y-2">
                <li>Unlimited access to our entire question bank</li>
                <li>
                  Run and submit as many queries as you want—no limits, no
                  restrictions
                </li>
                <li>
                  Get smart hints and personalized AI feedback to accelerate
                  your learning
                </li>
              </ul>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div
              className="cursor-pointer p-8 bg-white text-dnBlue rounded-lg shadow-md border border-gray-200 hover:shadow-xl transform transition-transform hover:-translate-y-2 hover:scale-105"
              onClick={() => handlePaymentOptionClick(1)}
            >
              <h3 className="text-2xl font-semibold">1 Month</h3>
              <p className="text-5xl font-extrabold mt-4 mb-6">$30</p>
              <p className="text-sm text-gray-500">One time payment</p>
            </div>

            <div
              className="cursor-pointer p-8 bg-white text-dnBlue rounded-lg shadow-lg border-4 border-dnPurpleDark hover:shadow-xl transform transition-transform hover:-translate-y-2 hover:scale-105"
              onClick={() => handlePaymentOptionClick(3)}
            >
              <h3 className="text-2xl font-semibold">3 Months</h3>
              <p className="text-5xl font-extrabold mt-4 mb-6">$60</p>
              <p className="text-sm text-gray-500">One time payment</p>
              <div className="block mt-4 bg-dnPurple text-white py-1 px-6 rounded-full text-sm font-semibold">
                Most Popular
              </div>
            </div>

            <div
              className="cursor-pointer p-8 bg-white text-dnBlue rounded-lg shadow-md border border-gray-200 hover:shadow-xl transform transition-transform hover:-translate-y-2 hover:scale-105"
              onClick={() => handlePaymentOptionClick(6)}
            >
              <h3 className="text-2xl font-semibold">6 Months</h3>
              <p className="text-5xl font-extrabold mt-4 mb-6">$100</p>
              <p className="text-sm text-gray-500">One time payment</p>
            </div>
          </div>

          <div className="flex justify-center mt-12">
            <div className="text-sm flex flex-row gap-1">
              <div className="text-black">Not ready yet? </div>
              <button
                className="text-sm text-dnPurple"
                onClick={() => {
                  mixpanel?.track("payment_back_to_questions", {
                    email: user?.email,
                    name: user?.fullName,
                  });
                  navigate("/");
                }}
              >
                Go back to questions
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumOptions;
