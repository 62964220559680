import React, { CSSProperties, useEffect, useState } from "react";
import { apiClient } from "../api/apiClient";
import SqlTable from "./sqlTable";
import { isArray } from "lodash";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-pgsql";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/src-noconflict/ext-language_tools";
import { TypeOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SyncLoader } from "react-spinners";
import { QuestionInfo } from "./questionInfo";
import { DifficultyLevel } from "./difficultyLevel";
import TagManager from "react-gtm-module";
import { useMixpanel } from "react-mixpanel-browser";
import { useNavigate, useParams } from "react-router-dom";
import { QUESTION_ID_PATH_PARAM } from "../App";
import { FaArrowLeft } from "react-icons/fa";
import SuccessPopup from "../components/SuccessPopup";
import { useUserContext } from "../authentication/userContext";
import Button from "../uiKit/button";
import {
  ANSWERS_STORAGE_NAME,
  QUERIES_STORAGE_NAME,
} from "../authentication/constants";
import { getQuestionsQuery } from "../api/questionsApi";
import { useQuery } from "@tanstack/react-query";
import { QuestionData } from "./types";
import { NodeSqlParserError, parseSqlParserError } from "./sqlEditorUtils";
import { Tooltip } from "react-tooltip";
import { isQuestionEnabled } from "../authentication/payment/paymentUtils";
import ace from "ace-builds";
import { sqlCompletions } from "./sqlCompletions";
import { getAnsweredQuestions } from "../utils/answeredQuestions";
import { updateUser } from "../api/usersApi";

const customCompleter = {
  getCompletions: (
    editor: any,
    session: any,
    pos: any,
    prefix: any,
    callback: any
  ) => {
    const completions = sqlCompletions;
    callback(null, completions);
  },
};

ace.config.loadModule("ace/ext/language_tools", (module) => {
  module.setCompleters([]);
  module.addCompleter(customCompleter);
});

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  marginBottom: "4rem",
};

export const QuestionView: React.FC = () => {
  const { user } = useUserContext();

  const [query, setQuery] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<Record<string, unknown>[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeToastId, setActiveToastId] = useState<number | string | null>(
    null
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const questionId = params[QUESTION_ID_PATH_PARAM] as string;
  const [questionData, setQuestionData] = useState<QuestionData | null>(null);
  const [isQuestionLocked, setIsQuestionLocked] = useState<boolean>(true);
  const [answeredQuestions, setAnsweredQuestions] = useState<
    Record<string, boolean>
  >(getAnsweredQuestions(user));

  const mixpanel = useMixpanel();

  const { data: questions = { data: [] }, isLoading: isLoadingPage } = useQuery(
    getQuestionsQuery(mixpanel)
  );

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const question = questions.data.find(
          (q: QuestionData) => q.id === questionId
        );
        setQuestionData(question);
        setIsQuestionLocked(!isQuestionEnabled(question, user));
      } catch (err) {
        console.error("Failed to fetch question:", err);
      }
    };

    fetchQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId, questions]);

  useEffect(() => {
    const queriesLocalStorage = localStorage.getItem(QUERIES_STORAGE_NAME);
    const queries = queriesLocalStorage ? JSON.parse(queriesLocalStorage) : {};
    setQuery(queries[questionId] || "");

    setError(null);
    setData(null);
  }, [questionData, questionId]);

  const onChange = (value: string) => {
    setQuery(value);
    setError(null);

    const queriesLocalStorage = localStorage.getItem(QUERIES_STORAGE_NAME);
    const queries = queriesLocalStorage ? JSON.parse(queriesLocalStorage) : {};

    const updatedQueries = { ...queries, [questionId]: value };
    localStorage.setItem(QUERIES_STORAGE_NAME, JSON.stringify(updatedQueries));
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleRunQuery = async (isSubmitted: boolean, questionId: string) => {
    try {
      setData(null);
      setError(null);
      setIsLoading(true);
      const response = await apiClient({
        path: "/run-query",
        method: "post",
        body: {
          query,
          questionId,
          isSubmitted,
        },
      });
      const success = response.success;
      const responseData = sortData(response.data.result);
      const isEqual = response.data.answer?.isEqual;
      const reason = response.data.answer?.reason;

      TagManager.dataLayer({
        dataLayer: {
          event: "run_query",
          questionId: questionData?.id,
          query,
          isEqual,
          reason,
          isSubmitted,
        },
      });

      mixpanel?.track("run_query", {
        questionId: questionData?.id,
        questionTitle: questionData?.title,
        query,
        isEqual,
        reason,
        isSubmitted,
        email: user?.email,
        name: user?.fullName,
        errorMessage: success ? null : responseData,
      });

      if (questionData && isSubmitted) {
        if (success) {
          if (isEqual) {
            setIsPopupOpen(true);
            toast.dismiss();
          } else {
            showToast(`That's not exactly right: ${reason}`, "info");
          }
        } else {
          showToast(
            "There's something wrong with the query. Fix it and try again",
            "error"
          );
        }

        if (isEqual) {
          updateAnsweredQuestions(questionData.id);
        }
      }
      setData(success ? responseData : null);
      setIsLoading(false);
      setError(success ? null : response.data);
    } catch (error) {
      setIsLoading(false);
      // @ts-ignore
      if (!error?.location?.start) {
        console.log("error:", error);
        mixpanel?.track("error", {
          type: "client_query_error",
          questionId: questionData?.id,
          query,
          email: user?.email,
          name: user?.fullName,
          error,
        });
        showToast("Something went wrong", "error");
      } else {
        const errorMsg = parseSqlParserError(error as NodeSqlParserError);
        isSubmitted &&
          showToast(
            "There's a parsing error in your query. Fix it and try again",
            "error"
          );
        setError(errorMsg);
      }
    }
  };

  const sortData = (data: Record<string, unknown>[]) => {
    if (!isArray(data)) return data;
    const sortedData = data
      .sort(
        (a, b) =>
          (a.internalTableIndex as number) - (b.internalTableIndex as number)
      )
      .map((item) => {
        const { internalTableIndex, ...rest } = item;
        return rest;
      });

    return sortedData;
  };

  const showToast = (text: string, type: TypeOptions) => {
    if (activeToastId) {
      toast.update(activeToastId, {
        render: text,
        onClose: onToastClose,
        type,
      });
    } else {
      const toastId = toast(text, {
        onClose: onToastClose,
        type,
      });
      setActiveToastId(toastId);
    }
  };

  const onToastClose = () => {
    setActiveToastId(null);
  };

  const buttonStyle = query?.trim() === "" ? "cursor-not-allowed" : "";

  const updateAnsweredQuestions = async (questionId: string) => {
    const updatedAnsweredQuestions = {
      ...answeredQuestions,
      [questionId]: true,
    };
    setAnsweredQuestions(updatedAnsweredQuestions);
    if (user) {
      await updateUser(user.id, {
        answeredQuestions: updatedAnsweredQuestions,
      });
    }
    localStorage.setItem(
      ANSWERS_STORAGE_NAME,
      JSON.stringify(updatedAnsweredQuestions)
    );
  };

  if (isLoadingPage) {
    return (
      <div className="flex items-center my-24 justify-center">
        <SyncLoader color={"#6456FF"} size={30} />
      </div>
    );
  }

  if (!questionData) return <div>Something went wrong...</div>;

  return (
    <div className="flex flex-col px-3 w-full h-fit">
      <div className="flex items-start mt-4 justify-between mr-3">
        <button
          onClick={() => navigate("/questions")}
          className="flex items-center text-gray-600 hover:text-gray-800 mr-4"
        >
          <FaArrowLeft className="mr-2" />
          <span>Back to Questions</span>
        </button>
      </div>
      <div className="text-start text-gray-800 mb-4 flex flex-row gap-6 items-center">
        <div className="font-bold text-2xl">{questionData.title}</div>
        <DifficultyLevel level={questionData?.difficulty} />
      </div>
      <div className="flex flex-col md:flex-row w-full flex-1 gap-2">
        <div className="w-full md:w-1/3 bg-gray-100 rounded-md max-h-96 overflow-y-auto">
          <QuestionInfo
            questionData={questionData}
            isQuestionLocked={isQuestionLocked}
            query={query}
          />
        </div>
        <div className="hidden md:block border-l border-gray-300 mx-4"></div>
        <div className="w-full md:w-2/3">
          <div className="mr-0 md:mr-8">
            <AceEditor
              placeholder="Enter your SQL query here"
              mode="pgsql"
              theme="terminal"
              name="editor"
              onChange={onChange}
              fontSize={14}
              lineHeight={24}
              value={query}
              style={{
                width: "100%",
                maxHeight: "331px",
                borderRadius: "6px",
              }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
              }}
            />
          </div>
          <div className="flex flex-col items-end space-y-2 my-2 md:mr-8">
            <div className="flex items-center justify-end space-x-4">
              <Button
                disabled={query?.trim() === ""}
                onClick={() => handleRunQuery(false, questionData.id)}
                className={`my-1 min-w-28 max-w-28 ${buttonStyle}`}
                variant="secondary"
              >
                Run Query
              </Button>
              <Button
                id="submit-button"
                disabled={query?.trim() === "" || isQuestionLocked}
                onClick={() => handleRunQuery(true, questionData.id)}
                className={`my-1 w-28 ${buttonStyle} ${isQuestionLocked ? "cursor-not-allowed" : ""}`}
              >
                Submit
              </Button>
              <Tooltip
                id="tooltip-submit"
                place="top-start"
                anchorSelect="#submit-button"
                content="Go premium to submit your answer"
              />
            </div>
            {error && (
              <div className="sql-editor__error text-red-500 mt-2 whitespace-pre max-h-32 overflow-x-auto overflow-y-hidden text-sm font-mono w-full">
                Error: {error}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="my-4"></div>
      <SyncLoader
        color={"#6456FF"}
        loading={isLoading}
        cssOverride={override}
        size={30}
      />
      {data !== null && (
        <div className="w-full mb-2">
          <SqlTable data={data} />
          <div className="mx-2 mt-2 text-dnBlue text-xs">
            {data.length} rows returned
          </div>
        </div>
      )}
      {/* {!isQuestionLocked && <QuestionFeedback questionId={questionId} />} */}
      {isPopupOpen && (
        <SuccessPopup onClose={handleClosePopup} questionId={questionId} />
      )}
    </div>
  );
};

export default QuestionView;
