import Button from "../../uiKit/button";
import { useUserContext } from "../userContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { buildStyles } from "react-circular-progressbar";
import { CircularProgressbar } from "react-circular-progressbar";
import { QuestionData } from "../../sqlEditor/types";
import { getQuestionsQuery } from "../../api/questionsApi";
import { useQuery } from "@tanstack/react-query";
import { useMixpanel } from "react-mixpanel-browser";
import { getAnsweredQuestions } from "../../utils/answeredQuestions";

const UserProfile = () => {
  const { user } = useUserContext();
  const mixpanel = useMixpanel();
  const navigate = useNavigate();
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [questions, setQuestions] = useState<QuestionData[]>([]);

  const { data: questionsResponse = { data: [], success: true } } = useQuery(
    getQuestionsQuery(mixpanel)
  );

  useEffect(() => {
    if (questionsResponse.success) {
      setQuestions(questionsResponse.data);
    }
  }, [questionsResponse]);

  useEffect(() => {
    const answeredQuestions = getAnsweredQuestions(user);
    const count = Object.values(answeredQuestions).filter(Boolean).length;
    setCorrectAnswersCount(count);

    const total = questions.length;
    setTotalQuestions(total);
    setProgressPercentage(total > 0 ? (count / total) * 100 : 0);
  }, [user, questions]);

  const subscriptionExpirationDate = user?.premiumExpiry
    ? new Date(user.premiumExpiry)
    : null;
  const isExpired = subscriptionExpirationDate
    ? subscriptionExpirationDate < new Date()
    : false;

  const subscriptionExpiration = subscriptionExpirationDate
    ? subscriptionExpirationDate.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "No active subscription";

  return (
    <div className="flex flex-col h-screen gap-8 p-4 bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-gray-800">Your Profile</h1>
        <p className="mt-4 text-lg text-gray-700">
          <span className="font-semibold">Name:</span> {user?.fullName}
        </p>
        <p className="mt-2 text-lg text-gray-700">
          <span className="font-semibold">Email:</span> {user?.email}
        </p>
        <p className="mt-2 text-lg text-gray-700">
          <span className="font-semibold">Subscription Expiry:</span>{" "}
          <span
            className={
              isExpired ? "bg-red-200 rounded-lg px-2 py-1" : "text-gray-700"
            }
          >
            {subscriptionExpiration}
          </span>
        </p>
        <div className="flex flex-col items-start mt-8">
          <div className="w-24 h-24 mb-4 relative ml-3">
            <CircularProgressbar
              value={progressPercentage}
              styles={buildStyles({
                textSize: "20px",
                pathColor: "#6456FF",
                textColor: "#1B3144",
                trailColor: "#e5e7eb",
                backgroundColor: "transparent",
                strokeLinecap: "round",
                pathTransitionDuration: 0.5,
              })}
            />
          </div>
          <div className="text-sm text-dnBlue w-32 text-center">
            {correctAnswersCount} / {totalQuestions} Questions Answered
            Correctly
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-8">
        <Button variant="secondary" onClick={() => navigate("/questions")}>
          Back to Questions
        </Button>
        {user?.isPremium ? (
          <Button onClick={() => navigate("/premium")}>
            Extend Subscription
          </Button>
        ) : (
          <Button
            onClick={() => {
              navigate("/premium");
            }}
            className="py-0.5 sm:py-2"
          >
            Go Premium
          </Button>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
